@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        ngopress
Version:        2.0
Last change:    02.20.2016
Primary use:    Attorney & Lawyer Business Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/

// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";

//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
/* @import "../css/elegant-icons.css"; */
/* @import "../css/stroke-gap-icons.css"; */
@import "../css/flaticon-set-charity.css";
@import "../css/utility-classes.css";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800|Raleway:200,300,400,500,600,700,800|Trirong:100,200,300,400,500,600,700,800,900);

 
// Table of contents
@import "less-ngopress/table-of-content.less";

 
// Initialize Variables
@import "less-ngopress/variables.less";
@import "less-ngopress/mixins.less";
// Typography
@import "less-ngopress/typography.less";

// Common Styles
@import "less-ngopress/common.less";
@import "less-ngopress/extra.less";
@import "less-ngopress/overlay.less";

// Header
@import "less-ngopress/header.less";

// Nav
@import "less-ngopress/nav.less";

// Content Blocks
@import "less-ngopress/topbar.less";
@import "less-ngopress/inner-header-title.less";
@import "less-ngopress/vertical-nav.less";
@import "less-ngopress/menu-full-page.less";
@import "less-ngopress/boxed-layout.less";
@import "less-ngopress/form.less";
@import "less-ngopress/side-push-panel.less";
@import "less-ngopress/box-hover-effect.less";
@import "less-ngopress/gallery-isotope.less";
@import "less-ngopress/sliders.less";
@import "less-ngopress/home.less";
@import "less-ngopress/about.less";
@import "less-ngopress/causes.less";
@import "less-ngopress/volunteer.less";
@import "less-ngopress/contact.less";
@import "less-ngopress/event.less";
@import "less-ngopress/practice-area.less";
@import "less-ngopress/services.less";
@import "less-ngopress/job.less";
@import "less-ngopress/shop.less";
@import "less-ngopress/blog.less";

// Shortcodes
@import "less-ngopress/shortcodes.less";


// Widgets
@import "less-ngopress/widgets.less";


// Footer
@import "less-ngopress/footer.less";
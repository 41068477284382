/*
 * Shortcode: team.less
 * -----------------------------------------------
*/

 .team-member {
  overflow: hidden;
  position: relative;
  .transition(all 0.5s linear 0s);

  &:hover::before {
    background: transparent;
    content: "";
    height: 100%;
    position: static;
    width: 90%;
  }

  .member-info {
    bottom: 100%;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 90%;
    .transition(all 0.4s linear 0s);

    .member-biography {
      opacity: 1;
      position: relative;
      right: auto;
      left: auto;
      top: auto;
      bottom: auto;
      text-align: center;
      z-index: 3;
    }
  }
  &:hover .member-info {
      bottom: 0;
  }
}
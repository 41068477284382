/*
 * Shortcode: toggles-accordions.less
 * -----------------------------------------------
*/
.panel-group {
	.panel {
		box-shadow: none;
		-webkit-border-radius: 0 0 0 0;
		-moz-border-radius: 0;
		-ms-border-radius: 0 0 0 0;
		border-radius: 0 0 0 0;
		border: none;
		position: relative;
	}
	.panel-title {
		font-size: 13px;
		a {
				background: fade(@gray-lighter, 50%);
		    display: block;
		    font-size: 14px;
		    padding: 10px 15px 10px 48px;
		    white-space: normal;
			&.active {
				background: @white-f1 none repeat scroll 0 0;
				border-bottom: 1px solid @gray-lightgray;
				color: @black-111;
				.open-sub:after {
					display: none;
				}

				&::after {
					color: @white-base;
					background: #e3e3e3;
					content: "\f068";
				}
			}			

			&::after {
			    color: @gray-light;
					content: "\f067";
			    font-family: fontawesome;
			    font-size: 14px;
			    left: 0;
			    padding: 10px 14px;
			    position: absolute;
			    top: 0;
			}
		}

		&:hover,
		&:focus {
			a {
				color: @black-333;
			}
		}
		.open-sub {
			display: block;
			position: relative;
			&:before, 
			&:after {
				content: "";
				display: block;
				position: absolute;
				height: 1px;
				top: 50%;
				margin-top: -.5px;
				left: 50%;
			}
		}
	}
	.panel-content {
		background: @white-f1 none repeat scroll 0 0;
		padding: 10px 20px 20px;
		> :last-child {
			margin-bottom: 0;
		}
	}
}

/* -------- Toggle ---------- */
.panel-group {
	&.toggle {
		.panel-heading {
			padding: 0;
			border-bottom: 0;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
		.panel-body {
			padding: 10px 0 5px 25px;
		}
	}
	&.accordion,
	&.toggle {
		&.transparent {
			.panel-title {
				a, a.active {
					background: transparent;
					border: none;
					&::after {
						color: @black-333;
						background: transparent;
					}
				}
			}
			.panel-content {
				background: transparent;
			}
		}
	}
}
.panel-group.toggle .panel-heading + .panel-collapse > .panel-body, 
.panel-group.toggle .panel-heading + .panel-collapse > .list-group {
	border-top: none;
}